<!-- <div class="modal-header">
    <h4 class="modal-title font-weight-bold" style="font-size: 17px;">Add Coupon</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">

    <div class="row" style="margin:0px; padding: 20px;">
        <form [formGroup]="couponForm">
            <label for="Name">Coupon Name:</label>
            <input name="Name" id="Name" formControlName="Name"/>

            <label for="UseLimit">Use Limit:</label>
            <input name="UseLimit" id="UseLimit" formControlName="UseLimit"/>

            <label for="ExpiryDate">Expiry Date:</label>
            <input name="ExpiryDate" id="ExpiryDate" formControlName="ExpiryDate"/>

            <label for="DiscountAmount">Coupon Name:</label>
            <input name="DiscountAmount" id="DiscountAmount" formControlName="DiscountAmount"/>
        </form>
    </div>
</div>

<hr />
<div class="col-sm-12">
    <button type="button" (click)="addCoupon()" class="eCount-def-btn  ml-4 p-save-button mb-4" style="float: right;">Add Coupon</button>
</div> -->



<div class="modal-header">
    <h4 class="modal-title font-weight-bold" style="font-size: 17px;">Create Coupon</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4" [formGroup]="couponForm">
        <div class="row">
            <div class="col-md-12">
                <div class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group col-sm-12">
                                <label for="Name" style="font-weight: 600;">Coupon Name: <span style="color: red;">*</span></label>
                                <input type='text' class="form-control" name="Name" id="Name" formControlName="Name"/>
                            </div>
                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group col-sm-12">
                                <label for="UseLimit" style="font-weight: 600;">Use Limit: <span style="color: red;">*</span></label>
                                <input type='text' class="form-control" name="UseLimit" id="UseLimit" formControlName="UseLimit"/>
                            </div>
                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group col-sm-12">
                                <label for="ExpiryDate" style="font-weight: 600;">Expiry Date: <span style="color: red;">*</span></label>
                                <!-- <input type="date" class="form-control" name="ExpiryDate" formControlName="ExpiryDate" max="{{today | date:'yyyy-MM-dd'}}" style="width: 99%;" id="dctest1" data-date="" data-date-format="D MMM YYYY" (change)="showSelectedDate($event)"> -->
                                <input type="date" class="form-control" name="ExpiryDate" id="ExpiryDate" formControlName="ExpiryDate" min="{{today | date:'yyyy-MM-dd'}}"/>
                            </div>
                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group col-sm-12">
                                <label for="DiscountAmount" style="font-weight: 600;">Discount Amount: <span style="color: red;">*</span></label>
                                <!-- <input type='text' class="form-control" name="DiscountAmount" id="DiscountAmount" formControlName="DiscountAmount" placeholder="Percent %"/> -->
                                <input type="text" class="form-control" name="DiscountAmount" id="DiscountAmount" formControlName="DiscountAmount" placeholder="Percent %" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <button type="button" (click)="addCoupon()" class="eCount-def-btn  mr-3 p-save-button mb-4" style="float: right;">Add Coupon</button>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div [formGroup]="couponForm">
        <div>
            <label for="Name">Coupon Name:</label>
            <input type='text' name="Name" id="Name" formControlName="Name"/>
        </div>
        <div>
            <label for="UseLimit">Use Limit:</label>
            <input type='number' name="UseLimit" id="UseLimit" formControlName="UseLimit"/>
        </div>
        <div>
            <label for="ExpiryDate">Expiry Date:</label>
            <input type="date" name="ExpiryDate" id="ExpiryDate" formControlName="ExpiryDate"/>
        </div>
        <div>
            <label for="DiscountAmount">Discount Amount:</label>
            <input type="number" name="DiscountAmount" id="DiscountAmount" formControlName="DiscountAmount" placeholder="Percent %" />
        </div>
    </div>
</div>
<hr /> -->
<!-- <div class="col-sm-12">
    <button type="button" (click)="addCoupon()" class="eCount-def-btn  ml-4 p-save-button mb-4" style="float: right;">Add Coupon</button>
</div> -->

