<app-dashboard>
    <app-page-header-title mainTitle="Company Menu Set Up" subTitle="Home" backURL="/">
        <div class="full-width text-align-right pb-2">
            <button type="button" (click)="saveList()" class="eCount-def-btn ml-4" >Save</button>
        </div>
    </app-page-header-title>
    <div id="controlPanel">
        <div class="form-panel-body">
            <div class="row">
                <span class="selectFont ml-2 mt-1">Select Company Type: </span>
                <div class="form-group col-sm-2">
                    <select class="form-control" style="width: 100%;height: 33px;" [(ngModel)]="BusinessTypeId" (change)="GetMenu(BusinessTypeId)">
                        <option value="1">Service</option>
                        <option value="2">Retail/Trading</option>
                        <option value="3">Manufacture</option>
                    </select>
                </div>
            </div>
            <div id="grid-block" *ngFor="let item of module">
                <h4 style="font-weight: bold">Module : {{item.ModuleName}} </h4>
                <table class="table table-hover table-light">
                    <thead>
                        <tr>
                            <th width="25%" class="font">Menu Title</th>
                            <th width="25%" class="font">Module</th>
                            <th width="15%" class="font">Include</th>
                        </tr>
                    </thead>
                    <tr *ngFor="let menu of item.BusinessTypeWiseMenuList">
                        <td width="25%">{{menu.ProjectMenu.Name}}</td>
                        <td width="25%">{{item.ModuleName}}</td>
                        <td width="15%" style="cursor: pointer;">
                            <div class="form-check form-check-inline">
                                <input 
                                    style="height: 15px; width: 15px;" 
                                    class="form-check-input" 
                                    type="checkbox" 
                                    [(ngModel)]="menu.IsActive"
                                    (ngModelChange)="checkboxChanged(menu)">
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</app-dashboard>