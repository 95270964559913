<nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom headerBg">
  <div class="sidebar-heading sidebarHead" style="width: 456px;">
      <a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="logo">
      <!-- <img class="logo" src="../../../assets//image/eCountLogoo.png"/> -->
      <img src="../../../assets/image/logos.png" alt="" class="logoNew">
      <span class="logoText ml-2">Admin Panel</span>
      </a>
  </div>

    <div class="f-ss-14px collapse navbar-collapse" id="navbarSupportedContent">

      <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
          
        <li class="ptp-12px prp-4px">
          <span (click)="this.changeLanguage('en')" [ngClass]="{'selected':selectedLanguage == 'en'}" class="languageText languageName pall-0 ml-2"> {{ 'HEADER.English' | translate}}  </span>
          <span class="pall-0" style="font-weight: 900;">|</span>
          <span (click)="this.changeLanguage('bn')" [ngClass]="{'selected':selectedLanguage == 'bn'}" class="languageText languageName pall-0 mr-2"> {{ 'HEADER.Bengali' | translate}} </span>
        </li>
        
        <li class="nav-item dropdown" style="width: 85px;">
          <a class="nav-link navbar-avatar userNameNavbar rounded-circle" data-toggle="dropdown" href="#" aria-expanded="false" data-animation="scale-up" role="button">
            <span>{{UserName}}</span>
          </a>
          <div class="dropdown-menu" role="menu">
            <a class="dropdown-item" href="javascript:void(0)" role="menuitem"><i class="icon wb-user" aria-hidden="true"></i> Profile</a>
            <a class="dropdown-item" href="javascript:void(0)" role="menuitem"><i class="icon wb-payment" aria-hidden="true"></i> Billing</a>
            <a class="dropdown-item" routerLink="/settings" role="menuitem"><i class="icon wb-settings" aria-hidden="true" (click)="goSettings()"></i> Settings</a>
            <a class="dropdown-item" routerLink="/company-wise-settings" role="menuitem"><i class="icon wb-settings" aria-hidden="true" (click)="goToCompanyWiseSettings()"></i>Company Wise Settings</a>
            <div class="dropdown-divider" role="presentation"></div>
            <a class="dropdown-item" href="javascript:void(0)" (click)="logout()" role="menuitem"><i class="icon wb-power" aria-hidden="true"></i> Logout</a>
          </div>
        </li>
        
        </ul>
    </div>
  </nav>