import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class DataService {
  isItemListUpdate = false; 
  isCheckDefaultSetting = false;
  isOrganizationUpdate = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Output() checkDefault: EventEmitter<boolean> = new EventEmitter();
  @Output() organizationUpdate: EventEmitter<boolean> = new EventEmitter();
  @Output() changeSelectedOrganization: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

   emitItemListUpdate(values : boolean) {
    this.isItemListUpdate = values;
    this.change.emit(this.isItemListUpdate);
  }

  emitDefaultNotificationUpdate(value: boolean){
    this.isCheckDefaultSetting = value;
    this.checkDefault.emit(this.isCheckDefaultSetting);
  }

  emitOrganizationUpdate(value:boolean){
    this.isOrganizationUpdate = value;
    this.organizationUpdate.emit(this.isOrganizationUpdate);
  }

  emitSelectedOrganizationChanged(value:boolean){
    this.changeSelectedOrganization.emit(value);
  }

}