export class ProjectMenu {
    Id: number;
    Name: string;
    ParentId: number | null;
    ExternalName: string;
    ActionUrl: string;
}

export class BusinessTypeWiseMenuViewModel{
    ProjectMenu: ProjectMenu;
    IsActive: number;
    BusinessTypeId: number;
}

export class CompanyWiseMenuViewModel{
    ProjectMenu: ProjectMenu;
    IsActive: number;
    BusinessTypeId: number;
    CompanyId: number;
}

export class MenuModuleViewModel{
    ModuleName: string;
    BusinessTypeWiseMenuList: BusinessTypeWiseMenuViewModel[];
}