<div class="modal-header">
    <div class="title-container">
        <h4 class="modal-title modalTitle">Subscription Details</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>

<div class="modal-body modalBody">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-4">
                <table style="color: #7F7F7F; font-size: 14px;">
                    <tr *ngIf="fromParent?.CompanyName != null">
                        <td class="font-weight-bold"> Company Name : </td>
                        <td>
                            {{fromParent?.CompanyName}}
                        </td>
                    </tr>
                    <tr *ngIf="fromParent?.BillToName != null">
                        <td class="font-weight-bold"> Name : </td>
                        <td>
                            {{fromParent?.BillToName}}
                        </td>
                    </tr>
                    <tr *ngIf="fromParent?.PhoneNo != null">
                        <td class="font-weight-bold"> Contact No : </td>
                        <td style="width: 185px;">
                            {{fromParent?.PhoneNo}}
                        </td>
                    </tr>
                    <tr *ngIf="fromParent?.BillToEmail != null">
                        <td class="font-weight-bold"> Email : </td>
                        <td>
                            {{fromParent?.BillToEmail}}
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-4">
                <table style="color: #7F7F7F; font-size: 14px;">
                    <tr *ngIf="fromParent?.CompanyStartDateVw != null">
                        <td class="font-weight-bold"> Company Start Date : </td>
                        <td>
                            {{fromParent?.CompanyStartDateVw}}
                        </td>
                    </tr>
                    <tr *ngIf="fromParent?.StartDateVw != null">
                        <td class="font-weight-bold" style="width: 170px;"> Subscription Start Date : </td>
                        <td>
                            {{fromParent?.StartDateVw}}
                        </td>
                    </tr>
                    <tr *ngIf="fromParent?.ExpiryDateVw != null">
                        <td class="font-weight-bold"> Expiry Date : </td>
                        <td>
                            {{fromParent?.ExpiryDateVw}}
                        </td>
                    </tr>
                    <tr *ngIf="fromParent?.PaidStatus != null">
                        <td class="font-weight-bold"> Payment Status : </td>
                        <td>
                            {{fromParent?.PaidStatus}}
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold"> Storage Limit : </td>
                        <td> 0.00 GB </td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold"> Storage Used : </td>
                        <td style="color: red;"> 0.00 GB </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-4">
                <table style="color: #7F7F7F; font-size: 14px;">
                    <tr *ngIf="fromParent?.PackageTypeName != null">
                        <td class="font-weight-bold"> Package Type : </td>
                        <td>
                            {{fromParent?.PackageTypeName}}
                        </td>
                    </tr>
                    <tr *ngIf="fromParent?.PackagePrice != null">
                        <td class="font-weight-bold"> Package Price : </td>
                        <td>
                            {{fromParent?.PackagePrice}}
                        </td>
                    </tr>
                    <tr *ngIf="fromParent?.Coupons?.Name != null">
                        <td class="font-weight-bold"> Coupon Name : </td>
                        <td>
                            {{fromParent?.Coupons?.Name}}
                        </td>
                    </tr>
                    <tr *ngIf="fromParent?.Coupons?.DiscountAmount != null">
                        <td class="font-weight-bold" style="width: 185px;"> Coupon Discount Amount : </td>
                        <td>
                            {{fromParent?.Coupons?.DiscountAmount}} %
                        </td>
                    </tr>
                    <tr *ngIf="fromParent?.TotalAmount != null">
                        <td class="font-weight-bold"> Paid : </td>
                        <td>
                            {{fromParent?.TotalAmount}}
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-12">
                <p class="font-weight-bold ml-5">Attached Document</p>
            </div>
            <div class="col-md-11 ml-5 pt-4" style="border: 1px solid #EDEBE9;">
                <div *ngFor="let file of AttachmentList" id="demo{{file.Id}}" (mouseout)="mouseOut(file.Id)" class="col-3">
                    <div id="hover{{file.Id}}" class="alls attachment-div" style="width: 250px;">
                        <div *ngIf="isPdfFile(file)">
                            <pdf-viewer [src]="src" [render-text]="true" [fit-to-page]="true" [original-size]="false" [zoom]="1"></pdf-viewer>
                        </div>
                        <div *ngIf="!isPdfFile(file)">
                            <img class="col-md-6" width="30%" style="border:none; margin:0 auto;width: 400px; height: 150px;" src={{fileUrl}}{{file.BlobName}} alt="..." class="img-thumbnail">
                            <p style="margin: 0 auto;width: 100%;text-align: center; font-size: 13px;">{{file.FileName}}</p>
                        </div>
                    </div>
                    <div style="text-align: center; width: 100%;">
                        <a class="option-icon-color" (click)="view(file)">
                            <i class="material-icons-outlined">insert_drive_file</i>
                        </a>
                        <a href={{fileUrl}}{{file.BlobName}} download={{file.BlobName}} class="option-icon-color">
                            <i class="material-icons">file_download</i>
                        </a>
                        <a (click)="print(file,null,isPdfFile(file))" class="option-icon-color">
                            <i class="material-icons-outlined">print</i>
                        </a>
                        <a (click)="share(file)" class="option-icon-color" data-toggle="modal" data-target="#shareModal">
                            <i class="material-icons-outlined">share</i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-smart-modal #AttachmentViewer identifier="AttachmentViewer">
    <div class="pb-4">
        <span class="modalHeaderText pl-2" class="attachment-header">{{selected?.FileName}}</span>
        <hr class="notShow">
        <div class="row pr-2 pl-2">
            <div class="col-md-12 notShow">
                <div class="row">
                    <div class="col-md-8 " style="text-align: right;">
                        <a>
                            <span class="option-icon-color" (click)="zoomIn()">
                                <i class="material-icons borderradiusleft attachmenticon">zoom_in</i>
                            </span>
                        </a>
                        <a>
                            <span class="option-icon-color" (click)="zoomOut()">
                                <i class="material-icons attachmenticon">zoom_out</i>
                            </span>
                        </a>
                        <a [hidden]="isPdf">
                            <span class="option-icon-color" (click)="rotate('left')">
                                <i class="material-icons attachmenticon">rotate_left</i>
                            </span>
                        </a>
                        <a [hidden]="isPdf">
                            <span class="option-icon-color" (click)="rotate('right')">
                                <i class="material-icons attachmenticon">rotate_right</i>
                            </span>
                        </a>
                        <a href={{fileUrl}}{{selected?.BlobName}} download={{selected?.BlobName}} class="option-icon-color">
                            <i class="material-icons attachmenticon">file_download</i>
                        </a>
                        <a>
                            <span (click)="print(selected,'contentToPrint',isPdf)" class="option-icon-color">
                                <i class="material-icons-outlined attachmenticon">print</i>
                            </span>
                        </a>
                        <a>
                            <span (click)="share(selected)" class="option-icon-color" data-toggle="modal" data-target="#shareModal">
                                <i class="material-icons-outlined borderradiusright border-right attachmenticon">share</i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div id="contentToPrint" class="col-md-12">
                <div>
                    <div style="overflow: overlay;">
                        <img id="image" style="width: 100%;" src={{src}} />
                    </div>
                </div>
            </div>
        </div>
    </div>
</ngx-smart-modal>
<hr />
<div class="col-sm-12" [hidden]="fromParent.PaidStatus=='Paid'">
    <button type="button" (click)="confirmPayment()" class="eCount-def-btn  ml-4 p-save-button mb-4" style="float: right;">Confirm Payment</button>
</div>