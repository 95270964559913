import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Coupons } from 'src/app/models/coupons.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CouponsServiceService {

  baseurl: string = environment.baseUrl;

  constructor(
    private http: HttpClient
  ) {}

  public GetAllCouponInformations(): Observable<any>{
    return this.http.get<string>(this.baseurl + 'Coupons/GetAllCouponInformations');
  }

  public DeleteCoupon(data: Coupons): Observable<any>{
    return this.http.post<Coupons>(this.baseurl + 'Coupons/DeleteCoupon', data);
  }

  public AddCoupon(data: Coupons): Observable<any>{
    return this.http.post<Coupons>(this.baseurl + 'Coupons/AddCoupon', data);
  }
  
}
