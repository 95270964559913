import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './service/auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private route : Router){}
  canActivate(): boolean{
    if (this.authService.IsLoggedIn()) {
      return true
    }else{
      this.route.navigate(['/auth/login']);
      return false;
    }
  }
  
}
