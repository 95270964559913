import { Component, OnInit,Input, Inject } from '@angular/core';
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { MainService } from '../../../service/Main/main-service.service';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import jspdf from 'jspdf';
import * as html2canvas from 'html2canvas';
@Component({
  selector: 'app-view-pending-subscription',
  templateUrl: './view-pending-subscription.component.html',
  styleUrls: ['./view-pending-subscription.component.css']
})
export class ViewPendingSubscriptionComponent implements OnInit {
Id: any;
src: any;
Data: any;
ispdf = false;
baseImageUrl = environment.fileBaseUrl;
  constructor(  public activeModal: NgbActiveModal,
                private mainService: MainService,
                private spinner: NgxSpinnerService,
                private activeRoute: ActivatedRoute,
                private router: Router,
                private toaster: ToastrService,
                private ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit(): void {
    this.Id  = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.getDetails();
  }
  getDetails() {
    this.spinner.show();
    this.mainService.GetDetails(this.Id).subscribe((x) => {
      this.Data = x;
      this.src = this.baseImageUrl + x.BlobName;
      this.spinner.hide();
      this.isPdfFile();
    });
  }

  confirmPayment() {
    this.spinner.show();
    this.mainService.confirmPayment(this.Id).subscribe((x) => {
      if (x.Success) {
        this.toaster.success("Subscription payment has been done successfully.");

        this.router.navigate(['registered-company/pending-company-list']);
        this.spinner.hide();
      }
    });

  }

  isPdfFile() {
    console.log(this.Data);
    let ext = this.Data?.BlobName.split(".")[1];
    this.ispdf = ext ==  'pdf' ? true : false;
  }
  convetToPDF = () => {
    var data = document.getElementById('pend');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      var DocumentName = `subscription details.pdf)`;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save(DocumentName);
    });
  }
}
