import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponentComponent } from './components/header-component/header-component.component';
import { FooterComponentComponent } from './components/footer-component/footer-component.component';
import { SidebarComponentComponent } from './components/sidebar-component/sidebar-component.component';
import { AuthHeaderComponent } from './components/auth-header/auth-header.component';
import { AuthFooterComponent } from './components/auth-footer/auth-footer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';


//service
import { AuthService } from './service/auth/auth.service';
import { MainService } from './service/Main/main-service.service';
import { DataService } from './service/EventEmitter/data.service';

//ngRx
import { StoreModule } from '@ngrx/store';
import { NgxSpinnerModule } from "ngx-spinner";
import { AgGridModule } from 'ag-grid-angular';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { reducer } from './reducers/accounting.reducer';
import { environment } from '../environments/environment';
import { JwtInterceptor } from './helper/jwt.interceptor';
import { ErrorInterceptor } from './helper/error.interceptor';
import { AuthGuard } from './auth.guard';
import { RouterLinkRendereComponent } from './components/router-link-rendere/router-link-rendere.component';
import { PageHeaderTitleComponent } from './components/page-header-title/page-header-title.component';
import { ServiceLoaderComponent } from './components/service-loader/service-loader.component';
import{ DecimaNumericValidator } from './helper/DecimalNumeric.validator';
import{ DisableControlDirective } from './helper/disable-control-directive';
import { PdfViewerModule } from 'ng2-pdf-viewer'; 
import { AppConfig } from './app.config';
import { ConfirmationDialogComponent } from './pages/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './service/confirmation-service/confirmation-dialog.service';
import { SubscriptionDetailsComponent } from './pages/subscription-details/subscription-details/subscription-details.component';
import { PrintFileComponent } from './pages/print/print-file/print-file.component';
import { PendingSubscriptionListComponent } from './pages/Pending-Subscription-List/pending-subscription-list/pending-subscription-list.component';
import { ViewPendingSubscriptionComponent } from './pages/Pending-Subscription-List/view-pending-subscription/view-pending-subscription.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { CompanyWiseSettingsComponent } from './pages/company-wise-settings/company-wise-settings.component';
import { CouponSettingsComponent } from './pages/coupon-settings/coupon-settings.component';
import { CouponAddModalComponent } from './pages/coupan-add-modal/coupon-add-modal/coupon-add-modal.component';
export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http)
}


@NgModule({
  declarations: [
    routingComponents,
    AppComponent,
    HeaderComponentComponent,
    FooterComponentComponent,
    SidebarComponentComponent,
    AuthHeaderComponent,
    AuthFooterComponent,
    DashboardComponent,
    RouterLinkRendereComponent,
    PageHeaderTitleComponent,
    ServiceLoaderComponent,
    DecimaNumericValidator,
    DisableControlDirective,
    ConfirmationDialogComponent,
    SubscriptionDetailsComponent,
    PrintFileComponent,
    PendingSubscriptionListComponent,
    ViewPendingSubscriptionComponent,
    SettingsComponent,
    CompanyWiseSettingsComponent,
    CouponSettingsComponent,
    CouponAddModalComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    DragDropModule,
    PdfViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    StoreModule.forRoot({ Accounting : reducer }),
    NgSelectModule,
    SelectDropDownModule,
    NgxSpinnerModule,
    AgGridModule.withComponents([]),
    Ng2SearchPipeModule,
    NgbModule
  ],
  providers: [
    AppConfig,
    AuthService,
    MainService,
    DataService,
    AuthGuard,
    NgbActiveModal,
    ConfirmationDialogService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
 
  ],
  entryComponents: [ ConfirmationDialogComponent],
  bootstrap: [AppComponent],
 
})
export class AppModule { }
