import { Component, OnInit } from '@angular/core';
import {  TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { AuthService } from 'src/app/service/auth/auth.service';
import { AuthGuard } from 'src/app/auth.guard';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-header-component',
  templateUrl: './header-component.component.html',
  styleUrls: ['./header-component.component.css']
})
export class HeaderComponentComponent implements OnInit {
  faCoffee = faBars;
  SelectedCompany: any
  contactList: any = []
  CompanyList: any = []
  Token:string = ''
  UserName:string='';
  selectedLanguage;
  constructor(public authService: AuthService,public translate: TranslateService, private router: Router,private dataService: DataService,private toastr : ToastrService) {
  }
  ngOnInit() {
    this.dataService.organizationUpdate.subscribe(()=>{
      this.initialiseOrganization();
    });
    this.getSelectedLanguage();
    this.initialiseOrganization();
  }

  initialiseOrganization(){
    var userInfo = this.authService.getUserInfo();
    console.log(userInfo);
    if (userInfo) {
      this.SelectedCompany = userInfo.SelectedCompany;
      this.CompanyList = userInfo.CompanyList;
      this.Token = userInfo.Token;
      this.UserName = userInfo.UserName;
    }
  }
  changeLanguage=(lang)=>{
    this.translate.use(lang)
    if (localStorage) {
      localStorage['language'] = lang;
    }
    this.getSelectedLanguage();
  }

  getSelectedLanguage=()=>{
    this.selectedLanguage = localStorage ? localStorage['language']:null;
  }

  showHideSidebar=()=>{
    $("#wrapper").toggleClass("toggled");
  }
  logout=()=>{
    localStorage.removeItem('userInfo');
    this.dataService.emitDefaultNotificationUpdate(true);
    this.router.navigate(['/auth/login']);
  }

  refreshPage() {
    window.location.reload();
   }

  hideBar=()=>{
    var element = document.getElementById("navBarTrigger1");
    element.classList.remove("show");
    var element = document.getElementById("navBarTrigger2");
    element.classList.remove("show");
  }

  goSettings() {
    this.router.navigate(['/settings']);
  }

  goToCompanyWiseSettings() {
    this.router.navigate(['company-wise-settings']);
  }

}
