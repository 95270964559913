import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  baseurl = environment.baseUrl;
  constructor(private http: HttpClient) { }

   GetCompanyList(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'AdminPanelSubscription/GetSubscribers').pipe(retry(1));
  }

  DeleteCompany(id): Observable<any> {
    return this.http.get<any>(this.baseurl + 'AdminPanelSupport/DeleteCompany?id='+id).pipe(catchError(this.errorHandler));

  }
  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }


  GetAttachment(id): Observable<any> {
    return this.http.get<any>(this.baseurl + 'SubscriptionPlan/GetSubscriptionFiles?Id='+id).pipe(retry(1));
  }
  GetDetails(id): Observable<any> {
    return this.http.get<any>(this.baseurl + 'SubscriptionPlan/GetSubscriptionDetails?Id=' + id).pipe(retry(1));
  }
  confirmPayment(id): Observable<any> {
    return this.http.get<any>(this.baseurl + 'SubscriptionPlan/ConfirmPayment?id='+id).pipe(retry(1));
  }
  updateTrialPeriod(id,date): Observable<any> {
    return this.http.get<any>(this.baseurl + `SubscriptionPlan/UpdateTrialPeriod?id=${id}&Date=${date}`).pipe(retry(1));
  }
  public GetPendingList(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + 'SubscriptionPlan/GetPendingList')
      .pipe(retry(1));
  }
  public ApprovePendingList(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + 'SubscriptionPlan/ApprovePendingSubscriptionList', data)
      .pipe(retry(1));
  }
}
