<app-header-component [hidden]="checkAuthRoute()"></app-header-component>
    <!-- Page content -->
    <div class="page-content">
        <app-sidebar-component [hidden]="checkAuthRoute()"></app-sidebar-component>
        <!-- Main content -->
        <div [ngClass]="{'content-wrapper': !checkAuthRoute()}">
            <div class="row" class="details-warning" *ngIf="isDefaultSetting" >
                <div class="col-md-12 alert alert-danger" role="alert">
                    <!-- You are using default setting . please change your organization type and financial year end setting. -->
                    
                    You can change organization type and financial end setting until you make any transaction.<a [routerLink]="'/settings/organization'"><span style="font-size: 13px;" >Change settings</span></a>
                </div>
            </div>
            <router-outlet></router-outlet>
        </div>

        <!-- Main content -->
    </div>
<app-service-loader></app-service-loader>
<!-- Page content -->
