
<app-dashboard>
    <app-page-header-title
        mainTitle="Company List"
        subTitle="Home"
        backURL="/">
    </app-page-header-title>
    <div class="row mb-4">
        <button class="eCount-def-btn ml-4" (click)="openAddCouponModal()" >Add Coupon + </button>
    </div>
    <div class="row" id="list">
        <div class="col-sm-12 col-md-12">
            <table class="table  f-ss-14px">
                <thead>
                    <tr class="tableHeader">

                        <th scope="col-sm-1" class="table-header-color">Coupon Name</th>
                        <th scope="col-sm-2" class="table-header-color">Use Limit</th>
                        <th scope="col-sm-2" class="table-header-color">Total Used</th>
                        <th scope="col-sm-2" class="table-header-color">Created At</th>
                        <th scope="col-sm-2" class="table-header-color">Discount Amount(%)</th>
                        <th scope="col-sm-2" class="table-header-color">Expiration Date</th>
                        <th scope="col-sm-1" class="table-header-color"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of couponsList">
                        <td class="">{{item.Name}}</td>
                        <td class="">{{item.UseLimit}}</td>
                        <td class="">{{item.TotalUsed}}</td>
                        <td class="">{{item.CreatedDate | date: "MMM dd, yyyy 'at' hh:mm a"}}</td>
                        <td class="">{{item.DiscountAmount + '%'}}</td>
                        <td class="">{{item.ExpiryDate | date: "MMM dd, yyyy 'at' hh:mm a"}}</td>
                        <td class="">
                            <div style="padding: 7px 10px; color: #c7c2c2;">
                                <div class="dropdown mr-4">
                                    <div role="button" style="cursor: pointer;" data-toggle="dropdown" aria-haspopup="false"
                                        aria-expanded="false">
                                        <i class="icon-more float-right"></i>
                                    </div>
                                    <div class="dropdown-menu context-menu-wrapper" aria-labelledby="navbarDropdown"
                                        style="width: 100%;">
                                        <div class="context-menu-item pl-1" style="cursor: pointer;" (click)="deleteCoupon(item)">
                                            <i class="icon-trash"></i><span class="context-menu-text pl-1">Delete</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</app-dashboard>