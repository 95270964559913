import { Component, OnInit, Input,TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-header-title',
  templateUrl: './page-header-title.component.html',
  styleUrls: ['./page-header-title.component.css']
})
export class PageHeaderTitleComponent implements OnInit {
  @Input() mainTitle: string;
  @Input() subTitle: string;
  @Input() backURL: string;
  @Input() rightAcion: boolean = true;
  @Input() searchBox: boolean;
  @Input() customTitleTemplate: TemplateRef<any>;
  customTemplate: TemplateRef<any>;
  
  constructor(private route: Router,) { }

  ngOnInit(): void {
    this.customTemplate = this.customTitleTemplate;
  }
  
  backToPreviousPage=()=>{
    console.log('onChange')
    this.route.navigate([this.backURL])
  }

}
