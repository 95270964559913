<div class="modal-header">
    <h5 class="modal-title">Delete [{{ title }}]</h5>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{ message }}
    </div>
    <div class="modal-footer">
      
      <button type="button" class="eCount-def-btn" (click)="accept()">{{ btnOkText }}</button>
      <button type="button" class="cancel btn-danger" (click)="decline()">{{ btnCancelText }}</button>
    </div>