import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BusinessTypeWiseMenuViewModel, CompanyWiseMenuViewModel, MenuModuleViewModel } from 'src/app/models/MenuModuleViewModel';
import { MainService } from 'src/app/service/Main/main-service.service';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';

@Component({
  selector: 'app-company-wise-settings',
  templateUrl: './company-wise-settings.component.html',
  styleUrls: ['./company-wise-settings.component.css']
})

export class CompanyWiseSettingsComponent implements OnInit {

  data: BusinessTypeWiseMenuViewModel[];
  filterModule: BusinessTypeWiseMenuViewModel[];
  changedMenuList: Array<CompanyWiseMenuViewModel> = [];

  module: MenuModuleViewModel[];
  CompnayId: number;
  CompanyList = [];

  constructor(
    private settingsService: SettingsServiceService,
    private spinner: NgxSpinnerService,
    private toastr : ToastrService,
    private mainService: MainService
  ) { }

  ngOnInit(): void {
    this.getAllCompany();
  }

  getAllCompany() {
    this.spinner.show()
    this.mainService.GetCompanyList().subscribe((x)=>{
      this.spinner.hide()
      this.CompanyList = x;
    })
  }

  GetMenuByCompanyWise(){
    this.spinner.show();
    this.settingsService.GetMenuByCompanyWise(this.CompnayId).subscribe((x) => {
      this.data = x;
      this.GetMenu();
      this.spinner.hide();
    });
  }


  GetMenu(){
    this.filterModule = this.data;
    const model: MenuModuleViewModel[] = [];
    const moduleList = this.filterModule.filter(x => x.ProjectMenu.ParentId == null && x.ProjectMenu.Id != 21);
    for (const i of moduleList) {
        const ModuleViewModel: MenuModuleViewModel = {
            ModuleName: i.ProjectMenu.Name,
            BusinessTypeWiseMenuList: this.filterModule.filter(x => x.ProjectMenu.ParentId == i.ProjectMenu.Id)
        };
        model.push(ModuleViewModel);
    }
    this.module = model;
  }

  checkboxChanged(menu: any) {
    if (!this.changedMenuList.includes(menu)) {
        this.changedMenuList.push(menu);
    }
  }

  saveList() {
    if(this.CompnayId == undefined || this.CompnayId == null || this.CompnayId == 0){
      this.toastr.warning('Please select your company!');
      return;
    }
    if(this.changedMenuList.length == 0){
      this.toastr.warning('You did not add or remove any features!');
      return;
    }

    const moduleList = this.filterModule.filter(x => x.ProjectMenu.ParentId == null && x.BusinessTypeId == this.changedMenuList[0].BusinessTypeId);
    for (const i of moduleList) {
      let isExistChildMenu = this.changedMenuList.filter(x => x.ProjectMenu.ParentId == i.ProjectMenu.Id).length > 0;
      if(isExistChildMenu && Boolean(i.IsActive) == false) {
        let companyWiseMenuViewModel = new CompanyWiseMenuViewModel();
        companyWiseMenuViewModel.ProjectMenu = i.ProjectMenu;
        companyWiseMenuViewModel.BusinessTypeId = i.BusinessTypeId;
        companyWiseMenuViewModel.IsActive = Number(true);
        this.changedMenuList.push(companyWiseMenuViewModel);
      }
    }

    this.changedMenuList.forEach(company => {
      company.CompanyId = this.CompnayId;
    })
    this.spinner.show();
    this.settingsService.AddOrDeleteMenuByCompnayWise(this.changedMenuList).subscribe((x) => {
      if(x.Success){
        this.toastr.success(x.Message);
        this.spinner.hide();
        this.changedMenuList = [];
        this.GetMenuByCompanyWise();
      }
      else{
        this.toastr.error(x.Message);
        this.spinner.hide();
        this.changedMenuList = [];
      }
    });
  }

}

