import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../service/Main/main-service.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as $ from 'node_modules/jquery';

@Component({
  selector: 'app-print-file',
  templateUrl: './print-file.component.html',
  styleUrls: ['./print-file.component.css']
})
export class PrintFileComponent implements OnInit {
  fileUrl = environment.fileBaseUrl;
  isPdf = false;
  files: any;
  id: any;
  src: any;
  AttachmentList: any = [];
  file: any;
  aid: number;
  constructor(private mainService: MainService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.aid = Number(this.route.snapshot.paramMap.get('id'));
    console.log(this.aid);
    this.test();
    document.addEventListener("DOMContentLoaded", function (event) {
      //do work
      
    });
  }

  getSelectFile = () => {
    this.file = this.AttachmentList.find(x => x.Id == this.aid);
    this.src = this.fileUrl + this.AttachmentList[0].BlobName;
    this.isPdf = this.AttachmentList[0].FileName.split('.')[1] === 'pdf' ? true : false;
    if (this.isPdf) {
      setTimeout(() => { window.print(); }, 3000);
    } else {
      $(document).ready(function () {
        console.log("Ready!");
        window.print();
      });
    }
  }
  test() {
    this.mainService.GetAttachment(this.aid).subscribe((x) => {
      console.log(x);
      this.AttachmentList = x;
      this.getSelectFile();
    });
  }
}
