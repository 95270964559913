import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from './service/auth/auth.service';
import { DataService } from './service/EventEmitter/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isDefaultSetting:boolean=false;
  constructor(private dataService: DataService, public translate: TranslateService, private router: Router,private authService: AuthService) {
    translate.addLangs(['bn', 'en']);
    translate.setDefaultLang('bn');
    if (localStorage) {
      let lang = localStorage['language']
      translate.use(lang ? lang : 'bn')
    }

  }

  checkAuthRoute=()=>{
    if (this.findSubRoute(this.router.url) =='print-file' ||  this.router.url == '/auth/login' || this.router.url == '/auth/reset-password' || this.router.url == '/auth/forgot' || this.router.url == '/auth/register' || this.removeParam(this.router.url) == '/accept-invitation' || this.findSubRoute(this.router.url) =='preview'||this.findSubRoute(this.router.url)=='print'){
      return true
    }else{
      return false
    }
  }
  
  removeParam=(parameter)=>{
    return parameter.split("?")[0]
  }

  findSubRoute=(param)=>{
    return param.split("/")[2];
  }
    
 
  
}
