
<app-dashboard>
    <app-page-header-title
        mainTitle="View Subscription Details"
        subTitle="Pending List"
        backURL="registered-company/pending-company-list">
        <div class="full-width text-align-right pb-2">
            <button type="button" (click)="confirmPayment()"
                class="eCount-def-btn ml-4" >Approve</button>
            <button 
                class="eCount-button3 ml-4" (click)="convetToPDF()">Export</button>
        </div>
    </app-page-header-title>
    <div id = "pend">
    <div class="row">
        <div class="col-md-12 d-flex f-ss-14px" id="my1">
            <div class="col-md-4 d-block pl-1">
              
                <div class="HeaderText">Company Name : <span class="font-weight-normal">{{  Data?.CompanyName  }}</span></div>
                <div class="HeaderText mt-2">Name : <span class="font-weight-normal">{{  Data?.BillToName  }} </span></div>
                <div class="HeaderText mt-2"> Contact No : <span class="font-weight-normal">{{  Data?.BillToEmail  }} </span></div>
                <div class="HeaderText mt-2">Email : <span class="font-weight-normal">{{Data?.PhoneNo}}</span></div>
            </div>
            <div class="col-md-4">
                <div class="HeaderText">Company Start Date : <span class="font-weight-normal">{{  Data?.StartDate }} </span></div>
                <div class="HeaderText mt-2">Subscription Start Date : <span class="font-weight-normal">{{  Data?.PaymentDate}} </span></div>    
                <div class="HeaderText mt-2">Expiry Date : <span class="font-weight-normal">{{  Data?.ExpiryDate }} </span></div>
                <div class="HeaderText mt-2">Company Status: <span class="font-weight-normal">{{  Data?.Status }} </span></div>
            </div>
            <div class="col-md-4">
                <div class="HeaderText">Package Type : <span class="font-weight-normal">{{  Data?.PackageTypeName  }}</span></div>
                <div class="HeaderText mt-2">Package Price: <span class="font-weight-normal"> {{ Data?.PackagePrice }} </span></div>
            </div>
        </div>
    </div>
    <p class="mt-4 HeaderText">Attached Document: </p>
    <div  class="alls attachment-div" style="width: 700px;">
            
        <!-- <img class="col-md-12" width="30%" style="border:none; margin:0 auto;" src="https://image.flaticon.com/icons/png/512/179/179483.png" alt="..." class="img-thumbnail"> -->
       <div *ngIf="ispdf == false">
        <img class="col-md-12" width="30%" style="border:none; margin:0 auto;width: 700px;" src={{src}}
            alt="..." class="img-thumbnail">
        <p style="margin: 0 auto;width: 100%;text-align: center; font-size: 13px;">{{Data?.FileName}}</p>
    </div>
    
</div>
</div>
<app-dashboard>