    <!-- Main sidebar -->
    <div class="sidebar sidebar-light sidebar-main sidebar-expand-md sideBarHeight">

        <!-- Sidebar mobile toggler -->
        <div class="sidebar-mobile-toggler text-center">
            <a href="#" class="sidebar-mobile-main-toggle">
                <i class="icon-arrow-left8"></i>
            </a>
            Navigation
            <a href="#" class="sidebar-mobile-expand">
                <i class="icon-screen-full"></i>
                <i class="icon-screen-normal"></i>
            </a>
        </div>
        <!-- /sidebar mobile toggler -->


        <!-- Sidebar content -->
        <div class="sidebar-content">
            <!-- Main navigation -->
            <div class="card card-sidebar-mobile">
                <ul class="nav f-ss-14px nav-sidebar" data-nav-type="accordion">
                    <li class="nav-item liBoarder">
                        <a class="nav-link navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block">
                            <i class="icon-paragraph-justify3"></i>
                        </a>
                    </li>
                    <li class="nav-item liBoarder">
                        <a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true} " class="nav-link active">
                            <i class="icon-home4"></i>
                            <span>
                                {{ 'SIDEBAR.dashboard' | translate}}
                            </span>
                        </a>
                    </li>
                    <li class="nav-item liBoarder">
                        <a [routerLink]="['/registered-company/company-list']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true} " class="nav-link">
                            <i class="icon-home4"></i>
                            <span>
                               Company List
                            </span>
                        </a>
                    </li>
                    <li class="nav-item liBoarder">
                        <a [routerLink]="['/registered-company/pending-company-list']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true} " class="nav-link">
                            <i class="icon-home4"></i>
                            <span>
                               Pending List
                            </span>
                        </a>
                    </li>
                    <li class="nav-item liBoarder">
                        <a [routerLink]="['/coupon-settings']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true} " class="nav-link">
                            <i class="icon-coins"></i>
                            <span>
                                Coupon Settings
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- /main navigation -->
        </div>
        <!-- /sidebar content -->    
    </div>
    <!-- /main sidebar -->
