import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard'
import {PrintFileComponent} from '../app/pages/print/print-file/print-file.component';
//Page Import
import { HomePageComponent } from './pages/home-page/home-page.component';
import { CompanyListPageComponent } from './pages/company-list/company-list.component';
import {SubscriptionDetailsComponent} from './pages/subscription-details/subscription-details/subscription-details.component'
//Authentication
import { AuthPageComponent } from './pages/Authentication/Login-page/auth-page.component';
import { PendingSubscriptionListComponent } from './pages/Pending-Subscription-List/pending-subscription-list/pending-subscription-list.component';
import { ViewPendingSubscriptionComponent } from './pages/Pending-Subscription-List/view-pending-subscription/view-pending-subscription.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { CompanyWiseSettingsComponent } from './pages/company-wise-settings/company-wise-settings.component';
import { CouponSettingsComponent } from './pages/coupon-settings/coupon-settings.component';
const routes: Routes = [
  { path: 'auth/login', component: AuthPageComponent },
  { path: '', component: HomePageComponent, canActivate: [AuthGuard] },
  { path: 'registered-company/company-list', component: CompanyListPageComponent , canActivate: [AuthGuard]},
  { path: 'subscription/subscription-details', component: CompanyListPageComponent , canActivate: [AuthGuard]},
  { path: 'file/print-file/:id', component: PrintFileComponent , canActivate: [AuthGuard]},
  { path: 'subscription/subscription-details/:id', component: ViewPendingSubscriptionComponent , canActivate: [AuthGuard]},
  { path: 'registered-company/pending-company-list', component: PendingSubscriptionListComponent , canActivate: [AuthGuard]},
  { path: 'settings', component: SettingsComponent , canActivate: [AuthGuard]},
  { path: 'company-wise-settings', component: CompanyWiseSettingsComponent , canActivate: [AuthGuard]},
  { path: 'coupon-settings', component: CouponSettingsComponent , canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [
  AuthPageComponent,
  HomePageComponent,
  CompanyListPageComponent,
  SubscriptionDetailsComponent,
  PendingSubscriptionListComponent
];
