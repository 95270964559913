import { Component, OnInit } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.css']
})
export class AuthHeaderComponent implements OnInit {
  faCoffee = faBars;
  selectedLanguage;
  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
    this.getSelectedLanguage();
  }
  changeLanguage = (lang) => {
    this.translate.use(lang)
    if (localStorage) {
      localStorage['language'] = lang;
    }
    this.getSelectedLanguage();
  }
  getSelectedLanguage=()=>{
    this.selectedLanguage = localStorage ? localStorage['language']:null;
  }
}
