import { Component, OnInit,Input, Inject } from '@angular/core';
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { MainService } from '../../../service/Main/main-service.service';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';
@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.css']
})
export class SubscriptionDetailsComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private mainService: MainService,
    private toaster: ToastrService,
    private ngxSmartModalService: NgxSmartModalService
  ) { }
  @Input() fromParent: any;
  AttachmentList: any=[];
  baseurl = environment.baseUrl;
  baseImageUrl = environment.fileBaseUrl;
  name: string = undefined;
  files: any;
  isPdf: boolean;
  zoom: number = 1;
  imagezooming: number;
  fileUrl = environment.fileBaseUrl;
  routes: string;
  users: [];
  searchText: string = "";
  imageSearch: string = "";
  url:string="";
  firstHalf:any;
  secondHalf:any;
  src: string;
  tempFiles:any;
  ngOnInit(): void {
    this.test()
  }
  
  test()
  {
    this.mainService.GetAttachment(this.fromParent.Id).subscribe((x)=>{
      console.log(x);
      this.AttachmentList = x;
      this.src = this.baseImageUrl + this.AttachmentList[0].BlobName;
    });
  }

  confirmPayment()
  {
    this.mainService.confirmPayment(this.fromParent.Id).subscribe((x)=>{
      if(x.Success)
      {
        this.toaster.success("Subscription payment has been done successfully.");
        this.activeModal.close(x);
      }
    })

  }

  downloadAttachment(item)
  {
     var url=this.baseImageUrl+item.BlobName;
     window.open(url,'_blank');
     url='';
  }
  rotationLeftAmount: number;
  rotationRightAmount: number;
  rotationAmount: number;
  rotate(direction: string) {
    this.rotationAmount = +90;
    if (direction == 'left') {
      this.rotationRightAmount -= 90;
      this.rotationLeftAmount += 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    } else {
      this.rotationRightAmount += 90;
      this.rotationLeftAmount -= 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    }
  }
  
  isPdfFile(file: any) {
    let ext = file.BlobName.split(".")[1];
    return ext == 'pdf' ? true : false;
  }
  selected: any;
  imagewidth: number;
  view(file: any) {
    this.selected = file;
    this.rotationRightAmount = 0;
    this.rotationLeftAmount = 0;
    let ext = file.FileName.split(".")[1];
    this.isPdf = ext == 'pdf' ? true : false;
    this.src = this.fileUrl + file.BlobName;
    this.imagezooming = 100;
    this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
    let img = document.getElementById('image');
    img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
  }


  share(file: any) {
    this.selected = file;
    let ext = file.FileName.split(".")[1];
    this.isPdf = ext == 'pdf' ? true : false;
    this.src = this.fileUrl + file.BlobName;
  }

  print(file: any, id: any, pdf: boolean){
    this.url = location.origin + "/#/";;
    window.open(`${this.url}file/print-file/${this.fromParent.Id}`, '_blank');
  }

  download() {
    this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
  }
  
  zoomOut() {
    if (this.isPdf) {
      this.zoom -= .1;
    } else {
      this.imagezooming -= 10;
      let element = document.getElementById("image")
      element.style.width = this.imagezooming + "%";
    }
  }

  zoomIn() {
    if (this.isPdf) {
      this.zoom += .1;
    } else {
      this.imagezooming += 10;
      let element = document.getElementById("image");
      element.style.width = this.imagezooming + "%";
    }
  }
  mouseOut(id: number) {
    
  }
  mouseOver(id: number) {
    let element;
    document.getElementById("options" + id).classList.add("show");
    
  }

}
