import { Component, OnInit } from '@angular/core';
import { faBars, faHome, faUsers, faCreditCard, faCoins, faClipboardList, faInfoCircle, faFileInvoice, faCogs, faHeart, faSignOutAlt, faUserPlus, faCalendarPlus, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';

declare var $: any;
@Component({
  selector: 'app-sidebar-component',
  templateUrl: './sidebar-component.component.html',
  styleUrls: ['./sidebar-component.component.css']
})
export class SidebarComponentComponent implements OnInit {
  HomeIcon= faHome
  Icons = { faBars, faUsers, faHome, faCreditCard, faCoins, faClipboardList, faInfoCircle, faFileInvoice, faCogs, faHeart, faSignOutAlt, faUserPlus, faCalendarPlus, faExchangeAlt }

  constructor() { }

  ngOnInit() {
    // var navClass = 'nav-sidebar',
    //   navItemClass = 'nav-item',
    //   navItemOpenClass = 'nav-item-open',
    //   navLinkClass = 'nav-link',
    //   navSubmenuClass = 'nav-group-sub',
    //   navSlidingSpeed = 250;
    // $('.' + navClass).each(function () {
    //   $(this).find('.' + navItemClass).has('.' + navSubmenuClass).children('.' + navItemClass + ' > ' + '.' + navLinkClass).not('.disabled').on('click', function (e) {
    //     e.preventDefault();

    //     // Simplify stuff
    //     var $target = $(this),
    //       $navSidebarMini = $('.sidebar-xs').not('.sidebar-mobile-main').find('.sidebar-main .' + navClass).children('.' + navItemClass);

    //     // Collapsible
    //     if ($target.parent('.' + navItemClass).hasClass(navItemOpenClass)) {
    //       $target.parent('.' + navItemClass).not($navSidebarMini).removeClass(navItemOpenClass).children('.' + navSubmenuClass).slideUp(navSlidingSpeed);
    //     }
    //     else {
    //       $target.parent('.' + navItemClass).not($navSidebarMini).addClass(navItemOpenClass).children('.' + navSubmenuClass).slideDown(navSlidingSpeed);
    //     }

    //     // Accordion
    //     if ($target.parents('.' + navClass).data('nav-type') == 'accordion') {
    //       $target.parent('.' + navItemClass).not($navSidebarMini).siblings(':has(.' + navSubmenuClass + ')').removeClass(navItemOpenClass).children('.' + navSubmenuClass).slideUp(navSlidingSpeed);
    //     }
    //   });

    // })
  //   $('.sidebar-main').find('.nav-sidebar').children('.nav-item-submenu').hover(function() {
  //     var totalHeight = 0,
  //         $this = $(this),
  //         navSubmenuClass = 'nav-group-sub',
  //         navSubmenuReversedClass = 'nav-item-submenu-reversed';

  //     totalHeight += $this.find('.' + navSubmenuClass).filter(':visible').outerHeight();
  //     if($this.children('.' + navSubmenuClass).length) {
  //         if(($this.children('.' + navSubmenuClass).offset().top + $this.find('.' + navSubmenuClass).filter(':visible').outerHeight()) > document.body.clientHeight) {
  //             $this.addClass(navSubmenuReversedClass)
  //         }
  //         else {
  //             $this.removeClass(navSubmenuReversedClass)
  //         }
  //     }
  // });
  }

  initSidebar=()=>{
    console.log('sub menu clicked')
      
  }

}