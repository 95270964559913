import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BusinessTypeWiseMenuViewModel, MenuModuleViewModel } from 'src/app/models/MenuModuleViewModel';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  data: BusinessTypeWiseMenuViewModel[];
  filterModule: BusinessTypeWiseMenuViewModel[];
  changedMenuList: Array<BusinessTypeWiseMenuViewModel> = [];

  module: MenuModuleViewModel[];
  BusinessTypeId = 1;

  constructor(
    private settingsService: SettingsServiceService,
    private spinner: NgxSpinnerService,
    private toastr : ToastrService
  ) { }

  ngOnInit(): void {
    this.GetMenuByBusinessType();
  }

  GetMenuByBusinessType(){
    this.spinner.show();
    this.settingsService.GetMenuByBusinessType().subscribe((x) => {
      this.data = x;
      this.GetMenu(1);
      this.spinner.hide();
    });
  }


  GetMenu(businessTypeId: number){
    this.filterModule = this.data.filter(x => x.BusinessTypeId == businessTypeId);
    const model: MenuModuleViewModel[] = [];
    const moduleList = this.filterModule.filter(x => x.ProjectMenu.ParentId == null && x.ProjectMenu.Id != 21);
    for (const i of moduleList) {
        const ModuleViewModel: MenuModuleViewModel = {
            ModuleName: i.ProjectMenu.Name,
            BusinessTypeWiseMenuList: this.filterModule.filter(x => x.ProjectMenu.ParentId == i.ProjectMenu.Id)
        };
        model.push(ModuleViewModel);
    }
    this.module = model;
  }

  checkboxChanged(menu: any) {
    if (!this.changedMenuList.includes(menu)) {
        this.changedMenuList.push(menu);
    }
  }

  saveList() {
    let filteredList = this.changedMenuList.filter(item => {
      const filterModuleItem = this.filterModule.find(x => x.ProjectMenu.Id === item.ProjectMenu.Id);
      return filterModuleItem && item.IsActive !== filterModuleItem.IsActive;
    });

    const moduleList = this.filterModule.filter(x => x.ProjectMenu.ParentId == null && x.BusinessTypeId == this.changedMenuList[0].BusinessTypeId);
    for (const i of moduleList) {
      let previousChildMenu = this.data.filter(x => x.ProjectMenu.ParentId == i.ProjectMenu.Id && x.BusinessTypeId == i.BusinessTypeId && Boolean(x.IsActive) == true)
      let newChildMenu = this.changedMenuList.filter(x => x.ProjectMenu.ParentId == i.ProjectMenu.Id && x.BusinessTypeId == i.BusinessTypeId && Boolean(x.IsActive) == true)
      if(previousChildMenu.length > 0 && newChildMenu.length > 0 &&  previousChildMenu.length == newChildMenu.length && this.changedMenuList.filter(x => x.ProjectMenu.ParentId == i.ProjectMenu.Id).length > 0) {
        this.changedMenuList.push(i);
      }
    }

    this.spinner.show();
    this.settingsService.AddOrDeleteMenuByBusinessType(this.changedMenuList).subscribe((x) => {
      if(x.Success){
        this.toastr.success(x.Message);
        //this.GetMenuByBusinessType();
        this.spinner.hide();
      }
      else{
        this.toastr.error(x.Message);
        //this.GetMenuByBusinessType();
        this.spinner.hide();
      }
    });
  }

}
