import { Component, OnInit, NgZone } from "@angular/core";
import { MainService } from "../../service/Main/main-service.service";
import { DataService } from "../../service/EventEmitter/data.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationDialogService } from "../../service/confirmation-service/confirmation-dialog.service";
import { SubscriptionDetailsComponent } from "../subscription-details/subscription-details/subscription-details.component";
import * as moment from "moment";

@Component({
  selector: "company-list-app",
  templateUrl: "./company-list.component.html",
  styleUrls: ["./company-list.component.css"],
})

export class CompanyListPageComponent implements OnInit {

  private gridApi;
  domLayout = "autoHeight";
  isItemListUpdate = false;
  searchText: string;
  CompanyList: any = [];
  Company: any = [];
  dateList: any = [];
  DateForm: FormGroup;
  DatePrevious: any;
  ExpiryDate: any;
  Id: number;

  constructor(
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private confirmationDialogService: ConfirmationDialogService,
    private toaster: ToastrService,
    private mainService: MainService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getAllCompany();
    this.dateList = [
      { label: "7 Days", Id: 7 },
      { label: "15 Days", Id: 15 },
      { label: "20 Days", Id: 20 },
    ];
  }

  getAllCompany = () => {
    this.spinner.show();
    this.CompanyList = [];
    this.mainService.GetCompanyList().subscribe((x) => {
      this.spinner.hide();
      this.CompanyList = x;
      this.Company = x;
    });
  }

  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  }

  openDetailsPopup(item) {
    const modalRef = this.modalService.open(SubscriptionDetailsComponent, { size: "xl", backdrop: "static", keyboard: false });
    modalRef.componentInstance.fromParent = item;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  deleteCompany(e) {
    this.confirmationDialogService.confirm(e.CompanyName, "Do you really want to delete the company and related all data ?").then((confirmed) => {
      this.spinner.show();
      if (confirmed) {
        this.mainService.DeleteCompany(e.CompanyId).subscribe((x) => {
            this.spinner.hide();
            if (x.Success) {
              this.toaster.success("Company has been deleted successfully.", "Success!");
            } else {
              this.toaster.warning("You do not have permission to delete for previous subscriptions.");
            }
            this.getAllCompany();
          },
          (err) => {
            this.toaster.error("Please Contact support team");
            this.spinner.hide();
          }
        );
      } else {
        this.spinner.hide();
      }
    })
    .catch(() => {
      this.spinner.hide();
    });
  }

  selectionChanged = (value) => {
    var l = value.length - 1;
    if (l >= 0) {
      this.Company = this.CompanyList.filter((x) => x.RemainingDays <= value[l]);
    } else {
      this.Company = this.CompanyList;
    }
  }

  openDateIncreser = (item) => {
    this.DatePrevious = this.convertDateIntoDisplay(item.ExpiryDate);
    this.Id = item.Id;
    this.ngxSmartModalService.create("DateChanger", "content").open();
  }

  submitExtendedDate = (item) => {
    if (item == undefined) {
      this.toaster.warning("Please fill all the required field !");
      return;
    }
    this.mainService.updateTrialPeriod(this.Id, item).subscribe((x) => {
      if (x.Success) {
        this.toaster.success("Trial period has been increased successfully.");
        this.ngxSmartModalService.getModal("DateChanger").close();
        this.getAllCompany();
      }
    });
  }

  initDateFrom = () => {
    this.DateForm = this.fb.group({
      ExpiryDate: new FormControl(),
    });
  }

  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY");
    return dateString;
  }

}
