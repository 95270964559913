<app-dashboard>
    <app-page-header-title mainTitle="Pending List" subTitle="Home" backURL="/">
        <div class="full-width text-align-right pb-2">
            <input  type="text"  placeholder="search" class="searchBox"  [(ngModel)]="searchText" (ngModelChange)="search()">
            <button type="button" (click)="getSelectedRowData()"
                class="eCount-def-btn ml-4" >Approve</button>
            <button 
                class="eCount-button3 ml-4" (click)="convetToPDF()">Export</button>
        </div>
        
    </app-page-header-title>

    <div class="pt-3" id="pl">
        <ag-grid-angular class="ag-theme-alpine"
        style="width: 100%; height: 100%"
        [rowData]="data"
        [columnDefs]="columnDefs"
        [domLayout]="domLayout"
        #topGrid
        id="topGrid"
        (cellClicked)="onCellClicked($event)"
        [defaultColDef]="defaultColDef"
        [rowSelection]="rowSelection"
        [suppressRowClickSelection]="true"
        (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</app-dashboard>
