import { Injectable } from "@angular/core";


@Injectable()
export class AppConfig
{
    public firebaseConfig = {
        apiKey: "AIzaSyBxxOXtX0DAswnZp6l6w6Ol-yYO4aZu_WM",
        authDomain: "ecount-77d54.firebaseapp.com",
        databaseURL: "https://ecount-77d54.firebaseio.com",
        projectId: "ecount-77d54",
        storageBucket: "ecount-77d54.appspot.com",
        messagingSenderId: "737183853791",
        appId: "1:737183853791:web:1874461c251b580d81cd6a",
        measurementId: "G-9PQ4GLN336"
      };

};
