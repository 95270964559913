import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Login, Register } from './../../models/auth.model';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedUserInfo: any 
  baseurl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  //updateLocalUserInfo
  updateLocalUserInfo(data): void {
    this.loggedUserInfo = data
  }

  // LoginService
  LoginService(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'AdminPanelAccount/Login', data).pipe(catchError(this.errorHandler));
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  } 

  IsLoggedIn(){
    const currentUser = JSON.parse(localStorage.getItem('userInfo'));
        if (currentUser && currentUser.Token){
          return true;
        }
        return false;
  }

  setUserInfo(info:string){
    localStorage.setItem('userInfo', info);
  }

  getUserInfo(){
    return JSON.parse(localStorage.getItem('userInfo'));
  }

  public logout(){
        localStorage.removeItem('userInfo');
    }

}
 