import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { customer } from './../../models/customer.model';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { BusinessTypeWiseMenuViewModel, CompanyWiseMenuViewModel } from 'src/app/models/MenuModuleViewModel';

@Injectable({
  providedIn: 'root'
})
export class SettingsServiceService {
  baseurl = environment.baseUrl;

  constructor(private http: HttpClient,private authService:AuthService,private router:Router) { }
 
  //save Settings
  saveSettings(data): Observable<any> {

    return this.http.post<any>(this.baseurl + 'Settings/Save', data)
      .pipe(
        retry(1)
      )
  }

  //Settings/GetConfig
  GetConfigSettings(): Observable<customer[]> {

    return this.http.get<any>(this.baseurl + 'Settings/GetConfig')
      .pipe(
        retry(1)
      )
  }

  //Settings/GetConfig
  GetPostingTypeList(): Observable<customer[]> {

    return this.http.get<any>(this.baseurl + 'Settings/GetPostingTypeList')
      .pipe(
        retry(1)
      )
  }


  GetOrganizationType(): Observable<any> {

    return this.http.get<any>(this.baseurl + `Settings/GetOrganizationTypes`)
      .pipe(
        retry(1)
      )
  }

  public GetMenuByBusinessType(): Observable<BusinessTypeWiseMenuViewModel[]>{
    return this.http.get<BusinessTypeWiseMenuViewModel[]>(this.baseurl + `Menu/GetMenuByBusinessType`).pipe(retry(1));
  }

  AddOrDeleteMenuByBusinessType(changedMenuList: BusinessTypeWiseMenuViewModel[]): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Menu/AddOrDeleteMenuByBusinessType', changedMenuList);
  }

  GetMenuByCompanyWise(companyId: number): Observable<BusinessTypeWiseMenuViewModel[]> {
    return this.http.get<BusinessTypeWiseMenuViewModel[]>(this.baseurl + `Menu/GetMenuByCompanyWise?companyId=${companyId}`).pipe(retry(1));
  }

  AddOrDeleteMenuByCompnayWise(changedMenuList: CompanyWiseMenuViewModel[]): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Menu/AddOrDeleteMenuByCompnayWise', changedMenuList);
  }

}