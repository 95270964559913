import { Action } from '@ngrx/store'
import { Accounting } from '../models/accounting.model'
import * as AccountingAction from '../actions/accounting.action'
import types from '../actions/types.action'

const initialState: Accounting = {
    chartOfAccounts: [
        {
            Id: 1,
            AccountCode: 12,
            AccountName: "y",
            IsDisabled: false,
            ParentId: 2,

        }
    ],
    AccountTypes: []
}

export function reducer(state: any = initialState, action: AccountingAction.Actions) {
    switch (action.type) {
        case types.ADD_CHART_OF_ACCOUNT:
            return [...state, action.payload];
        default:
            return state;
    }
}