import { Component, OnInit } from '@angular/core';
import { MainService} from '../../../service/Main/main-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import jspdf from 'jspdf';
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
@Component({
  selector: 'app-pending-subscription-list',
  templateUrl: './pending-subscription-list.component.html',
  styleUrls: ['./pending-subscription-list.component.css']
})
export class PendingSubscriptionListComponent implements OnInit {

  constructor( private mainService: MainService,
               private router: Router,
               private activeRouter: ActivatedRoute,
               private toastr: ToastrService,
               private spinner: NgxSpinnerService) { }
               domLayout = 'autoHeight';
               rowHeight = 275;
               gridApi;
               canApprove = false;
               data: any[];
               searchText: string;
               status: string;
               columnDefs = [
                 { headerName: 'Company No.', field: 'CompanyNo', cellStyle: {color: 'blue', cursor: 'pointer'},
                 headerCheckboxSelection: true,
                 headerCheckboxSelectionFilteredOnly: true,
                 checkboxSelection: true
                 // cellRendererFramework: RouterLinkRendereComponent,
                 // cellRendererParams: {
                 //     inRouterLink: '/expense/bill/',
                 //   }},
               },
               { headerName: 'Company Name', field: 'CompanyName' },
                 { headerName: 'Billing Name', field: 'BillToName' },
                 { headerName: 'Email', field: 'BillToEmail' },
                 { headerName: 'Contact No', field: 'PhoneNo' },
                 { headerName: 'Subscribed At', field: 'PaymentDate' },
                 { headerName: 'Pending Days', field: 'PendingDays' },
                 { headerName: 'Package Type', field: 'PackageTypeName' },
                 { headerName: 'Package Price', field: 'PackagePrice' },
             ];
               defaultColDef = {
                resizable: true,
                flex: 1,
                cellClass: 'cell-wrap-text',
                minWidth: 100
               };
               rowSelection = 'multiple';

  ngOnInit(): void {
    this.getPendingList();
  }
  onCellClicked(e) {
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == 'Company No.') {
      this.router.navigate(['subscription/subscription-details', e.data.Id]);
    }
  }
  onGridReady(event) {
    this.gridApi = event.api;
    this.gridApi.setHeaderHeight(35);
    this.gridApi.sizeColumnsToFit();
  }

  getPendingList() {
    this.spinner.show();
    this.mainService.GetPendingList().subscribe((x) => {
      this.data = x;
      this.spinner.hide();
    });
  }

  getSelectedRowData() {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    if (selectedData.length === 0) {
      this.toastr.warning('select at least one item');
      return;
    }

    this.approveData(selectedData);

  }

  approveData(selectedData) {
    this.spinner.show();
    this.mainService.ApprovePendingList(selectedData).subscribe((x) => {
      this.toastr.success('Approved successfully.');
      this.getPendingList();
      this.spinner.hide();
    });
  }


search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  }
  convetToPDF = () => {
    var data = document.getElementById('pl');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      var DocumentName = `Pending list.pdf)`;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save(DocumentName);
    });
  }
}
