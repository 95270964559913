
import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from './../../../service/auth/auth.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from 'src/app/service/EventEmitter/data.service';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.css']
})
export class AuthPageComponent implements OnInit {
  loginForm: FormGroup;
  IssueArr: any = [];
  constructor(private dataService:DataService,public fb: FormBuilder, private router: Router, public authService: AuthService, private toaster: ToastrService, private SpinnerService: NgxSpinnerService) { }

  ngOnInit() {
    this.loginData();
    if (this.authService.IsLoggedIn()) {
      this.router.navigate(['registered-company/pending-company-list']);
    }
  }

  loginData() {
    this.loginForm = this.fb.group({
      Email: [''],
      Password: ['']
    });
  }

  submitLoginForm() {
    this.SpinnerService.show();
    this.authService.LoginService(this.loginForm.value).subscribe(res => {
      this.authService.setUserInfo(JSON.stringify(res));
      this.router.navigate(['registered-company/pending-company-list']);
      this.SpinnerService.hide();
    }, err => {

        this.toaster.error("Email and password should be valid");
        this.SpinnerService.hide();
    });
  }

}
